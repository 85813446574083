import type { UserRole } from '../permissions'

export enum LegalReportAccessType {
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER'
}

export type LegalReportPermission = {
  email: string
  isInvite: boolean
  role: UserRole
  accessType: LegalReportAccessType
}

export type LegalReportPermissionUpdated = Omit<
  LegalReportPermission,
  'accessType'
> & {
  isNewInvite: boolean
  accessType: LegalReportPermission['accessType'] | null
}
