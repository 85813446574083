<template>
  <div class="access">
    <p class="access__description">
      {{ $t(titleKey) }}
    </p>
    <Loader v-if="!permissions" />
    <div
      v-else
      class="access__user-access"
    >
      <div
        v-for="permission in permissions"
        :key="permission.email"
        class="user-access"
      >
        <SettingsUserWithPicture :username="permission.email" />
        <div
          v-if="permission.role === UserRole.ADMIN && editMode"
          class="user-access__action"
        >
          <i class="action__icon icon-folder-lock" />
          <p class="action__text">
            {{ $t(`settings.usersPermissions.users.role.ADMIN`) }}
          </p>
        </div>
        <button
          v-else-if="permission.role !== UserRole.ADMIN && editMode"
          class="user-access__action user-access__action--active"
          @click="(event: Event) => openEditPermissionMenu(event, permission)"
        >
          <p class="action__text">
            {{ $t(`${accessTypeKey}.${permission.accessType}`) }}
          </p>
          <i class="action__icon action__icon--arrow icon-tiny-arrow" />
        </button>
        <div
          v-else
          class="user-access__action"
        >
          <p class="action__text">
            {{ $t(`${accessTypeKey}.${permission.accessType}`) }}
          </p>
        </div>
      </div>
    </div>

    <Teleport
      v-if="isEditPermissionMenuOpen"
      to="#action"
    >
      <ReflectOverlay @cancel="closeEditPermissionMenu">
        <div
          v-if="isEditPermissionMenuOpen"
          class="edit-module"
          :style="{
            top: editPermissionMenuPosition!.y + 'px',
            left: editPermissionMenuPosition!.x + 'px'
          }"
        >
          <button
            v-for="accessType in accessTypes"
            :key="accessType"
            class="menu__option"
            @click="updatePermission(accessType)"
          >
            <p class="option__text">
              {{ $t(`${accessTypeKey}.${accessType}`) }}
            </p>
            <i
              v-if="accessType === selectedPermission?.accessType"
              class="option__icon icon-success"
            />
          </button>
          <button
            class="menu__option menu__option--remove"
            @click="updatePermission(null)"
          >
            {{
              $t('settings.usersPermissions.accessPermissionTable.removeAccess')
            }}
          </button>
        </div>
      </ReflectOverlay>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { LegalReportAccessType } from '~/types/legalReports/permissions'
import {
  PermissionContext,
  UserRole,
  type AccessPermission
} from '~/types/permissions'
import { SourceAccessType } from '~/types/sources'

export default {
  name: 'SharePermissionTable',
  props: {
    titleKey: {
      type: String,
      required: true
    },
    accessTypeKey: {
      type: String,
      required: true
    },
    permissionContext: {
      type: String as PropType<PermissionContext>,
      required: true
    },
    permissions: {
      type: Array as PropType<
        AccessPermission<SourceAccessType | LegalReportAccessType>[] | null
      >,
      required: true
    },
    editMode: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update-access-type'],
  setup() {
    return {
      UserRole
    }
  },
  data(): {
    isEditPermissionMenuOpen: boolean
    selectedPermission:
      | AccessPermission<SourceAccessType | LegalReportAccessType>
      | undefined
    editPermissionMenuPosition: { x: number; y: number } | undefined
  } {
    return {
      isEditPermissionMenuOpen: false,
      selectedPermission: undefined,
      editPermissionMenuPosition: undefined
    }
  },
  computed: {
    accessTypes() {
      return this.permissionContext === PermissionContext.LEGAL_REPORT
        ? LegalReportAccessType
        : SourceAccessType
    }
  },
  methods: {
    openEditPermissionMenu(
      event: Event,
      permission: AccessPermission<SourceAccessType | LegalReportAccessType>
    ) {
      this.selectedPermission = permission

      const position = (
        event.currentTarget as HTMLDivElement
      ).getBoundingClientRect()

      this.editPermissionMenuPosition = {
        x: position.x,
        y: position.bottom
      }

      this.isEditPermissionMenuOpen = true
    },
    closeEditPermissionMenu() {
      this.isEditPermissionMenuOpen = false
      this.selectedPermission = undefined
    },
    updatePermission(
      accessType: LegalReportAccessType | SourceAccessType | null
    ) {
      this.$emit('update-access-type', {
        ...this.selectedPermission,
        accessType
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.access {
  &__description {
    @include font-text($font-weight-book);
    @include font-size($font-size-regular);
    margin: $margin-small 0;
  }

  &__user-access {
    height: 184px;
    overflow: auto;
    @include font-text;
    @include font-size($font-size-small);
    color: $text-primary;

    .user-access {
      display: flex;
      justify-content: space-between;
      margin: $margin-mini 0;

      &__action {
        display: flex;
        align-items: center;
        padding: $margin-tiny $margin-small;

        &--active {
          cursor: pointer;

          &:hover {
            background-color: $bg-quaternary;
          }
        }

        .action {
          &__text {
            @include font-text($font-weight-book);
            @include font-size($font-size-small);
          }

          &__icon {
            @include font-icon;
            @include font-size($font-size-intermediate);
            margin-right: $margin-tiny;

            &--arrow {
              @include font-size($font-size-mini);
              transform: rotate(90deg);
              margin-left: $margin-small;
            }
          }
        }
      }
    }
  }
}

.edit-module {
  @include block-shadow;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: $bg-primary;
  border-radius: 8px;
  overflow: hidden;

  .menu {
    &__option {
      position: relative;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $margin-mini $margin-small;
      cursor: pointer;
      @include font-text;
      @include font-size($font-size-regular);

      .option {
        &__icon {
          @include font-icon;
          @include font-size($font-size-mini);
        }
      }

      &:hover {
        color: $text-inverse;
        background-color: $button-primary;
      }

      &--remove {
        border-top: 1px solid $border-ternary;
        color: $text-warning;
      }
    }
  }
}
</style>
